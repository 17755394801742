/* You can add global styles to this file, and also import other style files */
@import "./assets/sass/style.scss";
// Replace above style with this css file to enable RTL styles
@import './assets/sass/plugins.scss';
// @import "./assets/css/style.rtl.css";
@import "./assets/sass/style.angular.scss";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

@import "./assets/sass/core/vendors/plugins/keenthemes-icons/font/ki.css";

@import "~@ng-select/ng-select/themes/material.theme.css";
@import url('https://fonts.googleapis.com/css2?family=Sarabun&display=swap');

.tox-notification {
  display: none !important
}

.ng-select .ng-has-value .ng-placeholder,
.ng-select.ng-select-opened .ng-placeholder,
.ng-select.ng-select-focused .ng-select-container .ng-value-container .ng-placeholder {
  transform: translateY(-1.64125em) scale(0.75) perspective(100px) translateZ(0.001px);
}

.ng-select {
  margin-top: -0.25em;

  .ng-spinner-loader {
    top: 0.1em;
  }

  .ng-select-container .ng-value-container {
    padding: 0.7375em 0;
  }

  .ng-select-container .ng-value-container {
    min-height: 2.8em;
  }

  .ng-select-container.ng-has-value .ng-value-container,
  .ng-select-container.ng-has-value .ng-value-container .ng-value {
    min-height: 1.2em !important;
  }

  .ng-select-container.ng-appearance-outline {
    min-height: 3.9em;
  }

  &.ng-select-single .ng-select-container.ng-appearance-outline .ng-clear-wrapper {
    bottom: 0.75em;
  }

  &.ng-select-single .ng-select-container.ng-appearance-outline .ng-arrow-wrapper {
    bottom: 1.2em;
  }

  .ng-clear-wrapper .ng-clear {
    font-size: 1.8em !important;
  }

  &.ng-select-single .ng-select-container .ng-value-container .ng-input {
    padding: 0 0.8em;
    top: 1.6em;
  }

  &.is-invalid .ng-select-container.ng-appearance-outline:after {
    border: solid 2px #f44336 !important;
  }

  &.ng-select-disabled .ng-select-container:after {
    background-image: none;
  }
}

.mat-form-field-appearance-outline {
  .mat-form-field-flex {
    margin-top: -0.45em;
  }

  .mat-form-field-infix {
    padding: 0.8em 0 1em 0;
  }
}

.mat-dialog-content {
  max-height: calc(100vh - 10em) !important;
}

table.table {

  th,
  td {
    min-width: var(--width);
  }

  th.col-fixed,
  td.col-fixed {
    width: var(--width);
    max-width: var(--width);
  }

  .col-number {
    --width: 2.4em;
  }

  .col-code {
    --width: 12em;
  }

  .col-type {
    --width: 10em;
  }

  .col-name {
    --width: 28em;
  }

  .col-image {
    --width: 12em;
  }

  .col-description {
    --width: 30em;
  }

  .col-amount {
    --width: 16em;
  }

  .col-modify-by {
    --width: 10em;
  }

  .col-modify-date {
    --width: 12em;
  }

  .col-status {
    --width: 6.4em;
  }

  .col-action {
    --width: 6.4em;
  }
}

textarea.mat-input-element.form-control {
  padding: 0.8em 1em;
  width: calc(100% - 2em);
}

input.mat-chip-input.form-control {
  margin: 0;
  padding-left: 1em;
  padding-right: 1em;
  width: calc(100% - 2em);
}